@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";
@import "../../assets/styles/helpers/variables";
@import "../../assets/styles/helpers/mixins";

.case-filter {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 12px;
  height: 100%;

  .case-filter__toggle-btn {
    width: 60px;
    height: 100%;
    color: #fff;
    @include mq("min-width", "xl") {
      display: none;
    }
    &:hover {
      color: #fff;
      background: $grey1;
    }
  }
  .case-filter__form {
    display: flex;
    flex-wrap: nowrap;
    transition:
      top 0.3s ease,
      opacity 0.3s ease;
    @include mq("max-width", "sm") {
      flex-direction: column;
      width: 90vw;
    }
    @include mq("max-width", "xl") {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 60%;
      padding: 20px 18px;
      min-width: 400px;
      background: #e8e8e8;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
    @include mq("min-width", "xl") {
      margin-bottom: -8px;
    }
    .case-filter__input {
      height: 30px;
      line-height: 30px;
    }
    button {
      @include mq("min-width", "sm") {
        margin-left: 16px;
      }
    }
  }
  &--is-open {
    .case-filter__toggle-btn {
      background: $grey1;
    }
    .case-filter__form {
      @include mq("max-width", "xl") {
        opacity: 1;
        visibility: visible;
        top: 100%;
      }
    }
  }
}
