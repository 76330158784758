@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

.sign-in__logo-container {
  max-width: 350px;
  width: 100%;
  margin: 40px auto 70px;
  text-align: center;
  height: $navbar-height;
  line-height: $navbar-height;
  overflow: hidden;
  padding: 0px 15px;

  .sign-in__logo {
    flex: 1;
    background-image: url("../../assets/images/topLogoGreen.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: $navbar-height;
  }
}

@media (max-width: 330px) {
  .sign-in__logo {
    margin-bottom: 17px;
  }
}
