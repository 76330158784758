.heading {
  color: #191919;
  font-size: 14px;
  font-weight: 600;
  overflow-wrap: break-word;
  &--uppercase {
    text-transform: uppercase;
  }
  &--h1 {
    font-size: 22px;
  }
  &--h2 {
    font-size: 18px;
  }
  &--h3 {
    font-size: 14px;
  }
  &--p {
    font-weight: 400;
  }
  &--span {
    display: inline-block;
    font-weight: 400;
  }
}
