.c-data-table {
  position: relative;
  padding: 26px 0 0 0;
  &--order-paper {
    padding: 0;
  }
  &--hideSizePerPage {
    padding: 0;
  }
  .c-data-table__sizeperpage {
    position: absolute;
    right: 0;
    top: 0;
  }
  .c-data-table__sizeperpage-label {
    margin: 0 8px 0 0;
    color: $grey4;
    font-size: 11px;
    font-weight: 600;
  }
  .c-data-table__sizeperpage-dropdown {
    .dropdown-toggle {
      padding: 0 8px;
      height: 22px;
      color: $grey2;
      font-size: 12px;
      background: $grey5;
      border-radius: 3px;
      border: none;
      &:hover {
        background: darken($grey5, 5%);
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:not(:disabled):not(.disabled):active:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .dropdown-menu {
      width: 100%;
      max-width: 100%;
      min-width: auto;
      border-radius: 0;
      .dropdown-item {
        padding: 2px;
        height: 24px;
        text-align: center;
        font-size: 12px;
        border-radius: 0;
        a {
          display: block;
          width: 100%;
          color: $grey3;
        }
      }
    }
  }
  .c-data-table__actions {
    display: flex;
    justify-content: space-between;
  }
  .c-data-table__pagination {
    .pagination {
      justify-content: center;
      .page-item {
        &.active {
          .page-link {
            color: $brand-color-2;
            background: $brand-color-1;
            border-radius: 3px;
          }
        }
      }
      .page-link {
        color: $brand-color-1;
        background: none;
        border: none;
        transition: color 0.3s ease;
        &:hover {
          color: $grey1;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .c-data-table__export {
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 2px;
  }
}
