.item {
  margin: 0 0 8px 0;
}

.header {
  position: relative;
  padding: 18px 48px 18px 24px;
  background: #f7f7f7;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 10px;
    vertical-align: 10px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}
.headerIsOpen {
  &::after {
    transform: rotate(180deg);
  }
}

.body {
  display: none;
  height: 0;
  overflow: hidden;
  background: #fcfcfc;
}

.bodyIsOpen {
  display: block;
  padding: 18px 24px;
  min-height: 0px;
  height: auto;
  overflow: visible;
}
