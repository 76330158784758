.c-form-control {
  display: block;
  padding: 0.375rem 0.75rem;
  width: 100%;
  height: $input-height;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dedede;
  border-radius: 3px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: nth($background-colors, 5);
  }
}
