$brand-color-1: #003f5f;
$brand-color-2: #ffffff;
$brand-color-3: #e4ecf2;
$brand-color-4: #007698;
$btn-primary-bg: #3ca0bc;

$module-section-title-bg: darken($brand-color-3, 5%);
$module-section-filter-bg: $brand-color-3;
$skin-bg: lighten($brand-color-3, 5%);

:root {
  --btn-primary-bg: $btn-primary-color;
  --brand-color-1: $brand-color-1;
  --brand-color-2: $brand-color-2;
  --module-section-title-bg: $module-section-title-bg;
  --module-section-filter-bg: $module-section-filter-bg;
  --skin-bg: $skin-bg;
}

$btn-primary-bg-hover: darken($btn-primary-bg, 10%);

// to clean
$blue1: #0094d4;
$blue2: #324d5c;
$blue4: #6f9fba;
$border-color: #ced4da;
$bright-red: #c30000;
$dark-background: #343a40;
$focus-color: #80bdff;
$green3: #00594d;
$grey1: #292829;
$grey2: #414042;
$grey2a: #626064;
$grey3: #888b8d;
$grey4: #c4c5c7;
$grey5: #e1e1e2;
$grey6: #f3f3f3;
$grey7: #f5f5f5;
$indigo: #865e9c;
$input-border-color: #ced4da;
$input-height: 36px;
$light-gray-border: #dee2e6;
$light-red: #de9090;
$orange1: #e27b40;
$red1: #dd5b49;
$sea-green: #00816d;
$signal-yellow: #ffb81c;
$white: #ffffff;

$background-colors: #6d6b6f #f3f3f3 $brand-color-1 #343a40 #e4e4e5;
$borders-colors: #cacbcd #ced4da;
$scrollbar-colors: #cacbcd #6d6b6f;
$text-colors: $brand-color-1 #605f62 #929597 #6d6b6f #003f5f $brand-color-2;
$box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
$charts-colors: #395667 #0094d4 #00ac6e #ff8d1c #e16652 #7aa8c1;
$button-colors: #605f62 $brand-color-1 #e74c3c;
$button-hover-colors: #3c3c3d #00905d #c0392b;
$button-text-colors: #ffffff #ffffff #ffffff;
$scoring-colors: #28a745 #ffb81c #c30000 #929597;
