.l-box {
  margin: 16px 0 0 0;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);

  &--relative {
    position: relative;
  }

  .l-box__head {
    padding: 14px 0;
    h1 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .l-box__body {
    padding: 0 0 10px 0;
    font-size: 14px;
  }
}
