$headerHeight: 60px;
$footerHeight: 55px;
$logoBarMobileHeight: 60px;

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $skin-bg;
  &--fixed-height {
    height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;
  }
  .layout__main {
    position: relative;
    display: flex;
    flex: 1;
  }
  .layout__skin {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 230px);
    &--centered-content {
      .layout__skin-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
