.c-select-list {
  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  &__filter {
    margin-bottom: 18px;
  }
  select {
    position: absolute;
    visibility: hidden;
  }
}

.c-input-error {
  margin: 5px 0 0 0;
  color: red;
  font-size: 12px;
  text-align: right;
}
