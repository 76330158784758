.pi__action-buttons {
  margin-top: 10px;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.pi__save-button {
  margin-left: 0;
  margin: 0 5px;
  padding: 5px 30px;
  cursor: pointer;
}

.pi__cancel-button {
  margin-right: 0;
  margin: 0 5px;
  padding: 5px 30px;
  cursor: pointer;
}

.pi__form-group {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.pi__label {
  flex: 0 1 15%;
  align-self: center;
  max-width: 70px;
}

.pi__input {
  $self: &;
  flex: 1 1 15%;

  &--textarea {
    min-height: 80px;
  }
}
