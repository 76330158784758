@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

$transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;

.messages-footer {
  .sticky-footer {
    margin-top: -55px;
  }
}
.sticky-footer {
  background-color: $brand-color-1;
  transition: $transition;
  bottom: 0;
  color: $white;
  height: $footer-height;
  line-height: $footer-height;
  z-index: 820;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .sticky-footer__body {
    background: url("../../assets/images/company-logo-white.png") no-repeat center center;
    background-size: contain;
    width: 180px;
    height: 35px;
  }
}
