.cookied {
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #052d44;
    color: white;
    padding: 5px;
  }

  &__cookieicon {
    margin-right: 10px;
  }

  &__cookietext {
    text-align: center;
  }

  &__closeicon {
    margin-left: 10px;
    height: 50px;
    i {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
