@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/helpers/variables";
@import "../../../assets/styles/helpers/mixins";

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  @include mq("min-width", "lg") {
    flex-wrap: wrap;
  }
  @include mq("max-width", "lg") {
    flex-direction: column;
  }
  &__header {
    padding: 20px;
    width: 100%;
    border-bottom: 1px solid #ebedf2;
    &--secondary {
      padding: 10px;
    }
  }
  &__content {
    position: relative;
    padding: 20px;
    @include mq("min-width", "lg") {
      flex: 1;
    }
    &--padding-0 {
      padding: 0;
    }
  }
}

.card-aside {
  padding: 24px;
  background: #f8f8f8;
  border-right: 1px solid #e2e2e2;
  height: 100%;
  // @include mq('min-width', 'lg') {
  //     width: 30%;
  //     max-width: 300px;
  // }
  &--secondary {
    padding: 0;
  }
}

.card__content--secondary {
  padding: 0;
}
