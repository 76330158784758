@mixin btn-set-1($border-bg: #cacbcd) {
  font-size: 12px;
  font-weight: 600;
  border: 1px solid $border-bg;
  box-shadow: 0 1px 5px #efefef;
  outline: 1px solid transparent;
  border-radius: 2px;
  transition: background-color 0.5s ease;
}

.c-btn {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  transition: background ease 0.3s;
  &:focus {
    outline: none;
  }
  i {
    padding-right: 10px;

    &.left {
      padding-right: 10px;
    }

    &.right {
      padding-right: 0;
      padding-left: 10px;
    }
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &--primary,
  &.c-btn--main {
    color: nth($text-colors, 6);
    background: $btn-primary-bg;
    &:hover {
      background: $btn-primary-bg-hover;
    }
    .c-btn__loader {
      color: #fff;
    }
  }
  &--danger {
    color: #fff;
    background: #c0392b;
    &:hover {
      background: darken(#c0392b, 5%);
    }
  }
  &--transparent {
    background: transparent;
  }
  &--icon {
    i {
      padding: 0;
    }
  }
  &--icon-right {
    i {
      padding-left: 10px;
    }
  }
  &.c-btn--link {
    position: relative;
    background: transparent;
    &::after {
      content: "";
      position: absolute;
      bottom: 4px;
      left: 10px;
      right: 10px;
      height: 1px;
      background-color: #000;
    }
    &.c-btn--disabled {
      background: transparent;
      &::after {
        background-color: $grey4;
      }
    }
  }
  &.c-btn--type-2 {
    color: $brand-color-2;
    background: $grey1;
  }
  &.c-btn--type-3 {
    color: $grey2;
    background: transparent;
  }
  &.c-btn--xs {
    font-size: 12px;
    i {
      font-size: 12px;
    }
  }
  &.c-btn--sm {
    height: 30px;
    font-size: 14px;
    border-radius: 2px;
  }
  &.c-btn--large {
    min-width: 100px;
  }
  &.c-btn--large,
  &.c-btn--md {
    justify-content: center;
    height: 30px;
    font-size: 14px;
    text-align: center;
  }
  &.c-btn--1 {
    color: nth($font-colors, 3);
    background: #f4f4f4;
    @include btn-set-1(#929597);
    &:hover {
      background: darken(#f4f4f4, 5%);
    }
  }
  &.c-btn--color-primary {
    color: #fff;
    background: $brand-color-1;
  }
  &.c-btn--toggle {
    &::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }

  &--prev {
    justify-content: center;
    margin: 0 10px 0 0;
    padding: 0.125rem 0.45rem;
    width: 155px;
    height: 30px;
    color: #414042;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    background: #e1e1e2;
    @include btn-set-1(transparent);
    &:hover {
      color: nth($text-colors, 6);
      background: #414042;
    }
  }

  &--next {
    justify-content: center;
    margin: 0 0 0 10px;
    padding: 0.125rem 0.45rem;
    width: 255px;
    height: 30px;
    color: nth($text-colors, 6);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    background: $brand-color-1;
    @include btn-set-1(transparent);
    &:hover {
      background: #414042;
    }
    .c-btn__icon {
      margin: 0 0 0 10px;
    }
  }

  &.c-btn--active {
    color: nth($text-colors, 6);
    background: nth($font-colors, 2);
    @include btn-set-1;
    &:hover {
      background: darken(nth($font-colors, 2), 5%);
    }
  }

  &.c-btn--xl {
    width: 100%;
    height: 100%;
  }

  &.c-btn--left {
    justify-content: left;
  }

  &.c-btn--download {
    background: none;
  }

  .c-btn__loader {
    margin-left: 10px;
  }

  &.c-btn--icon-only {
    i {
      padding-right: 0;
    }
  }
  &--loader {
    &.c-btn--color-secondary {
      .c-btn__loader {
        color: #49484b;
      }
    }
  }
}
