.cas {
  &__table {
    margin-top: 30px;
    table-layout: fixed;
    thead tr th:first-of-type() {
      width: 250px;
    }

    tbody tr th:first-of-type() {
      width: 250px;
    }
  }

  &__content {
    text-align: center;
    margin-bottom: 40px;
  }

  &__content-header,
  &__table-header {
    padding: 20px 10px;
    text-align: center;
  }
}
