.skin {
  &--fixed-height {
    height: 100%;
  }
  .skin__row {
    position: relative;
    padding: 0 0 16px 0;
    &--full {
      .container {
        @include mq("min-width", "md") {
          max-width: none;
        }
      }
    }
    &--last {
      &::after {
        display: none;
      }
    }
    &--empty {
      padding: 0;
    }
  }
  .skin__content {
    padding: 0 16px;
    &.container {
      @include mq("min-width", "medium") {
        margin: 0;
      }
    }
  }
}
