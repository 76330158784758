.c-creator-list {
  display: flex;
  margin: 0 -4px;
  padding: 0;
  overflow-y: auto;
  list-style: none;
  &--2 {
    flex-wrap: wrap;
    max-height: 304px;
    overflow-y: auto;
    @include scroll;
    .c-creator-list__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2px;
      padding: 0 12px;
      width: 100%;
      height: 32px;
      color: #4a494b;
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      cursor: pointer;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;
      &:hover {
        color: nth($text-colors, 6);
        background-color: #49484b;
      }
      &--active {
        color: nth($text-colors, 6);
        background-color: #49484b;
        border: none;
        box-shadow: 1px 2px 4px -2px #49484b;
      }
    }
  }
  &--opacity-5 {
    opacity: 0.5;
  }
}
