.c-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: 0 0 0 20px;
  padding: 6px 12px;
  height: 44px;
  border-radius: 5px;
  box-shadow: 0px 4px 5px #efefef;
  box-shadow:
    0 3px 12px 0 #ddd,
    3px 3px 9px 6px rgba(0, 0, 0, 0);
  &.c-box--1 {
    .c-box__icon {
      color: nth($font-colors, 3);
    }
    .c-box__amount-value {
      color: nth($font-colors, 3);
    }
  }
  &.c-box--2 {
    .c-box__icon {
      color: nth($font-colors, 2);
    }
    .c-box__amount-value {
      color: nth($font-colors, 2);
    }
  }
  &.c-box--3 {
    .c-box__icon {
      color: nth($font-colors, 4);
    }
    .c-box__amount-value {
      color: nth($font-colors, 4);
    }
  }
  &.c-box--4 {
    .c-box__icon {
      color: nth($font-colors, 5);
    }
    .c-box__amount-value {
      color: nth($font-colors, 5);
    }
  }
  &.c-box--5 {
    .c-box__icon {
      color: nth($font-colors, 1);
    }
    .c-box__amount-value {
      color: nth($font-colors, 3);
    }
  }
  &.c-box--6 {
    .c-box__icon {
      color: nth($font-colors, 7);
    }
    .c-box__amount-value {
      color: nth($font-colors, 7);
    }
  }
  .c-box__icon {
    position: absolute;
    left: -22px;
    margin-top: -8px;
    font-size: 30px;
    font-weight: 600;
    transform: translateY(50%);
  }
  .c-box__amount {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
  }
  .c-box__currency {
    margin: 0 0 0 4px;
    color: #9b9ea0;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .c-box__desc {
    margin-top: 2px;
    color: #888b8d;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
  }
}

.opacity-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  .opacity-box-background {
    &.opacity-box-background--transparent {
      background: rgba(0, 0, 0, 0);
    }
  }
}
