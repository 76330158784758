.idm {
  .idm__btn-container {
    display: flex;
    justify-content: center;
    .btn.btn-success {
      display: flex;
      text-transform: unset;
      .fa-envelope {
        font-size: 170%;
      }
      .fa-plus {
        font-size: 80%;
        vertical-align: super;
      }
    }
  }

  .idm-table {
    .idm-table__col {
      &--break-text {
        word-break: break-all;
      }
    }
  }
}
