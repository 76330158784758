@import "../../assets/styles/base/colors";

.case-payments {
  overflow-x: auto;

  .table {
    width: unset;
    & thead th {
      border-top: 0;
      width: 160px;
    }
    .align-to-right {
      text-align: right;
    }
    .total {
      background-color: $grey6;
    }
  }
}
