@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/base/colors";
/*Loader*/

@keyframes lds-wedges {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-wedges {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lds-css {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  &.small {
    width: 50px;
    margin-right: 0;
    .lds-wedges {
      width: 50px !important;
      height: 50px !important;
      -webkit-transform: translate(-25px, -25px) scale(0.5) translate(25px, 25px);
      transform: translate(-25px, -25px) scale(0.5) translate(25px, 25px);
      & > div > div {
        -webkit-transform-origin: 50px 50px;
        transform-origin: 50px 50px;
        & > div {
          left: 15px;
          top: 15px;
          width: 35px;
          height: 35px;
          border-radius: 35px 0 0 0;
          -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
        }
      }
    }
  }
  .lds-wedges {
    position: relative;
    width: 100px !important;
    height: 100px !important;
    -webkit-transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
    transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
    & > div {
      div {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          & > div {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
        }
        &:nth-child(1) {
          -webkit-animation-duration: 1.15s;
          animation-duration: 1.15s;
          & > div {
            background: $brand-color-1;
            // background: #00594f;
          }
        }
        &:nth-child(2) {
          -webkit-animation-duration: 1.533333333333333s;
          animation-duration: 1.533333333333333s;
          & > div {
            opacity: 0.8;
            background: $brand-color-1;
          }
        }
        &:nth-child(3) {
          -webkit-animation-duration: 2.3s;
          animation-duration: 2.3s;
          & > div {
            opacity: 0.6;
            background: $brand-color-1;
          }
        }
        &:nth-child(4) {
          -webkit-animation-duration: 4.6s;
          animation-duration: 4.6s;
          & > div {
            opacity: 0.4;
            background: $brand-color-1;
            // background: #e5f2f0;
          }
        }
      }
      & > div {
        -webkit-transform-origin: 100px 100px;
        transform-origin: 100px 100px;
        -webkit-animation: lds-wedges 4.6s linear infinite;
        animation: lds-wedges 4.6s linear infinite;
        opacity: 0.8;
        & > div {
          position: absolute;
          left: 30px;
          top: 30px;
          width: 70px;
          height: 70px;
          border-radius: 70px 0 0 0;
          -webkit-transform-origin: 100px 100px;
          transform-origin: 100px 100px;
        }
      }
    }
  }
}

.loader {
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .lds-css {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &--small {
    .lds-css {
      width: 50px;
      margin-right: 0;
      .lds-wedges {
        width: 50px !important;
        height: 50px !important;
        -webkit-transform: translate(-25px, -25px) scale(0.5) translate(25px, 25px);
        transform: translate(-25px, -25px) scale(0.5) translate(25px, 25px);
        & > div > div {
          -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
          & > div {
            left: 15px;
            top: 15px;
            width: 35px;
            height: 35px;
            border-radius: 35px 0 0 0;
            -webkit-transform-origin: 50px 50px;
            transform-origin: 50px 50px;
          }
        }
      }
    }
  }
  &--loaded {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &--overlay {
    background: #fff;
    &.hide-background {
      background: $grey6;
    }
    .lds-css {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &--overlay-semi {
    background: rgba(243, 243, 243, 0.4);
    .lds-css {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .loader__msg {
    color: $brand-color-1;
    font-size: 14px;
  }

  .loader__text {
    background-color: white;
    padding: 10px 20px 90px 20px;
    font-weight: bold;
  }
}
