@import "./colors";
@import "./settings";

$font-colors: $brand-color-1;

@each $font-color in $font-colors {
  $i: index($font-colors, $font-color);
  .font-color-#{$i} {
    color: #{$font-color};
    &:hover {
      color: #{$font-color};
    }
  }
}

.step {
  text-align: center;

  &-content {
    margin-bottom: 20px;
    position: relative;

    p {
      margin: 0;
    }
  }

  &-body {
    &.agreement-content {
      p {
        margin-bottom: 1rem;
        text-align: justify;
      }
    }
  }

  & &-content {
    padding: 50px 0;
  }

  &-footer {
    margin-top: 50px;
    text-align: center;
  }

  &-header {
    margin-bottom: 20px;
    text-align: center;

    .step-title {
      color: $brand-color-1;
    }
  }

  &-section {
    border-top: 1px solid $grey5;
    padding: 20px 0 0;
  }

  &-title {
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
    &.normal {
      text-transform: none;
    }
  }

  .highlighted {
    color: $brand-color-1;
    font-weight: bold;
  }
}

label {
  &.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    padding-right: 10px;
    cursor: pointer;
  }
}

.step-link {
  font-weight: bold;
  color: $brand-color-1;

  &:hover {
    color: $brand-color-1;
  }
}

@media (min-width: $small-screen) {
  .small-form {
    &-75 {
      margin: 0 auto;
      width: 85%;
    }
    &-60 {
      margin: 0 auto;
      width: 80%;
    }
  }
}

@media (min-width: $medium-screen) {
  .small-form {
    &-75 {
      margin: 0 auto;
      width: 75%;
    }
    &-60 {
      margin: 0 auto;
      width: 60%;
    }
  }
}

.pdf-container {
  width: 100%;
  height: 500px;
}

.concent-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: $white;
  }
  &::-webkit-scrollbar {
    width: 20px;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brand-color-1;
  }
}

mark {
  background: none;
  &.green {
    color: $brand-color-1;
    font-weight: bold;
  }
}

strong {
  &.green {
    color: $brand-color-1;
  }
}

p {
  &.note {
    color: $grey3;
    font-size: 11px;
  }
}

.spinner-inside {
  transition: all ease 0.3s;
  .form-control {
    padding-right: 30px;
    position: relative;
  }
  .spinner-border {
    position: absolute;
    top: 7px;
    right: 24px;
  }
}

.text-transform-none {
  text-transform: none;
}

.checkbox-inline {
  margin-right: 10px;
  margin: 6px;
  input {
    margin-right: 5px;
  }
}
