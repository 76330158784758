@import "../../../assets/styles/base/colors";

$menu-collapse-bg: $grey1;
$menu-item-color: $brand-color-1;
$menu-item-active-color: $brand-color-1;
$menu-item-active-bg: $brand-color-3;
$menu-item-hover-color: $brand-color-1;
$menu-item-hover-bg: $brand-color-4;
$menu-item-border-color: #3a3a3a;
$menu-item-icon-color: $brand-color-1;

.c-mng-menu {
  &__move-btn {
    background-color: unset;
    color: $white;
  }

  .c-mng-menu__item {
    position: relative;
    display: flex;
    flex-direction: column;
    &::before {
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      height: 1px;
      bottom: 0;
      background-color: $menu-item-border-color;
    }
    &--opened {
      background-color: $menu-item-active-bg;
    }
    &--manage {
      display: flex;
      flex-direction: row;
    }
  }
  .c-mng-menu__link {
    position: relative;
    display: block;
    padding: 10px 20px 10px 46px;
    color: $brand-color-1;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover,
    &:focus {
      background-color: $menu-item-hover-bg;
      color: $menu-item-hover-color;
      text-decoration: none;
    }
    &.active {
      background-color: $menu-item-active-bg;
      color: $menu-item-active-color;
      border-right: 4px solid $brand-color-1;
      &.c-mng-menu__link--root {
        border-right: none;
      }
    }
    &--root {
      border-right: none;
      &:after {
        content: "\f0de";
        position: absolute;
        top: 14px;
        right: 25px;
        color: $menu-item-icon-color;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }
    }
    &--sub {
      padding-left: 44px;
    }
    &.closed {
      &:after {
        top: 6px;
        transform: rotate(180deg);
      }
    }
    i {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      margin-right: 5px;
    }
  }

  .c-mng-menu-group__header {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    margin: 15px 0 0 0;
    padding: 5px 0 5px 20px;
    color: #787878;
    font-size: 12px;
    border: none;
    cursor: default;
    &::before {
      display: none;
    }
  }

  &__action-btns {
    display: flex;
    background-color: transparent;
    flex: 1;
    justify-content: flex-end;
    &::before {
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      height: 1px;
      bottom: 0;
      background-color: $menu-item-border-color;
    }
  }
}

.c-mng-menu-sidebar {
  min-height: 100%;
  width: 320px;
  background-color: $brand-color-2;
  margin: 20px;

  .sidebar {
    height: auto;
  }
  .dropdown-nav-link {
    .collapse,
    .collapsing {
      background: $grey1;
    }
  }
  @include mq("max-width", "medium") {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 830;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transform: translate(-$leftMenuWidth, 0);
    border-top: 1px solid #353535;
    &.opened {
      transform: translate(0px, 0);
    }
  }

  &__group {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 5px 0;
  }

  &__group-title {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
}
