@import "./colors";
@import "./settings";

@each $color in $button-colors {
  $i: index($button-colors, $color);
  $fontColor: nth($button-text-colors, $i);
  $hoverColor: nth($button-hover-colors, $i);
  .btn-color-#{$i} {
    background: #{$color};
    color: #{$fontColor};
    &:hover {
      background: #{$hoverColor};
      color: #{$fontColor};
    }
    &--disabled {
      opacity: 0.7;
      &:hover {
        background: #{$color};
      }
    }
  }
}

.button-table {
  min-width: 70px;
  float: right;
  button {
    font-size: 12px;
    text-transform: none;
    padding: 2px 7px;
  }
  .dropdown-menu {
    max-height: none;
    overflow-y: hidden;
    a {
      font-size: 13px;
    }
  }
}

.btn {
  font-weight: bold;
  text-transform: uppercase;
  &--normal {
    text-transform: none;
  }
  &.mt-m15 {
    margin-top: -15px;
  }
  &.btn--xs {
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
  &.btn--circle {
    border-radius: 50%;
  }
  &.btn--top-right-corner {
    position: absolute;
    top: -15px;
    right: -5px;
  }
  &.btn--visible-on-hover {
    transition: $transition;
    opacity: 0;
  }
}

.btn-success {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-bg;
  &:hover {
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-bg-hover;
  }
}

.info-icon {
  color: $red1;
}

.btn-default {
  background-color: $grey6;
  border-color: $grey3;
  text-transform: none;
  font-weight: normal;
  text-align: left;
  font-size: 0.95rem;

  &:hover:not(:active) {
    background-color: $grey4;
  }

  &:active {
    @extend .btn-success;
    color: nth($text-colors, 6);
    &.btn-cancel {
      background-color: $bright-red;
      border-color: $bright-red;
    }
  }
}

.btn--full-width {
  width: 100%;
}
