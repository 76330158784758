.wrapper {
  width: 100%;
}

.label {
  margin: 0 0 6px 0;
  color: #858585;
  font-size: 12px;
  text-transform: uppercase;
}

.value {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
