@import "./colors";
@import "./settings";
@import "../helpers/variables";
@import "../helpers/mixins";

.module {
  display: flex;
  margin-bottom: $min-margin;
  // height: 100%;

  &-body {
    position: relative;
    flex-grow: 1;
    padding: 16px;
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    box-shadow: 0 0 16px #ddd;
    background-color: #fff;
    @include mq("max-width", "xl") {
      margin-bottom: 20px;
    }
  }
  &-sidebar {
    background-color: $grey6;
    font-size: 13px;
    padding: $min-margin;
    width: 170px;
    &__title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  &__title {
    border-bottom: 1px solid $grey5;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 4px;
    text-transform: uppercase;
    &--add-on {
      font-weight: normal;
    }
  }
}

.module-box {
  height: 100%;
  &.module-box--secondary {
    .module-box__content {
      margin: 0;
      padding: 0;
      box-shadow: none;
    }
  }
  .module-box__content {
    margin-bottom: 15px;
    padding: 20px;
    height: calc(100% - 15px);
    background: $white;
    border-radius: 3px 3px 0 0;
    box-shadow: $box-shadow;
  }
  .module-box__header {
    border-bottom: 1px solid nth($background-colors, 5);
    padding-bottom: 5px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    strong {
      text-transform: uppercase;
    }
  }
  .svg-header {
    font-weight: bold;
    font-size: 8px;
    fill: nth($text-colors, 4);
  }
}

.case-icon,
.case-progress-icon,
.ctl-icon {
  font-family: "Font Awesome 5 Free";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  &--pre-legal {
    font-weight: 900;
    &::before {
      content: "\F09D";
    }
  }
  &--reminder {
    font-weight: 900;
    &::before {
      content: "\F70E";
    }
  }
  &--legal {
    font-weight: 900;
    &::before {
      content: "\F0E3";
    }
  }
  &--surveillance {
    font-weight: 400;
    &::before {
      content: "\F06E";
    }
  }
  &--invoice {
    font-weight: 900;
    &::before {
      content: "\F570";
    }
  }
}
