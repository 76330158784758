@each $font-weight in $font-weights {
  .font--#{$font-weight} {
    font-weight: #{$font-weight};
  }
}

@each $font-color in $font-colors {
  $i: index($font-colors, $font-color);
  .font--color-#{$i} {
    color: #{$font-color};
    &:hover {
      color: #{$font-color} !important;
    }
  }
}

.text {
  &.text--aligned {
    display: flex;
    align-items: center;
  }
}

.font {
  &.font--size-13 {
    font-size: 13px;
  }
}
