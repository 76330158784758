.accordion {
  > .card {
    overflow: visible;
  }
}
.card {
  .card-header {
    position: relative;
    cursor: pointer;
  }
  .card-header__icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    font-size: 20px;
  }
}
