@import "./colors";
@import "../helpers/variables";
@import "../helpers/mixins";

.c-form {
  margin: 0;
  .c-form__group {
    display: flex;
    margin: 0 0 16px 0;
    &:last-of-type {
      margin: 0;
    }
  }
}

.col-form-label {
  font-weight: bold;
}

.col-form-field {
  display: flex;
  align-items: center;
}

.col-form-label,
.form-control {
  font-size: 14px;
  line-height: 17px;
}

.form-control {
  border-radius: 0;
  padding: 3px 6px;
  height: $input-height;
}

.form-control {
  &.standard {
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
  }
}

.form-check {
  &-input {
    margin-top: 0.2rem;
  }
  &-label {
    font-weight: bold;
  }
  &--wrapper {
    margin: 20px 0;
  }
}

.radio-small-boxes {
  list-style: none;
  padding-left: 0;
  li {
    display: inline-block;

    @include mq("max-width", "sm") {
      width: 100%;
    }

    label {
      margin-bottom: 0;
      background: $white;
      padding: 7px 35px 7px 12px;
      margin-right: 15px;
      font-weight: bold;
      border: 1px solid nth($borders-colors, 2);
      border-radius: 3px;
      list-style-position: inside;

      @include mq("max-width", "sm") {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    input[type="radio"] {
      margin-left: 10px;

      @include mq("max-width", "sm") {
        right: 20px;
      }
    }
    &.disabled {
      label {
        cursor: not-allowed;
      }
      &:not(.active) {
        label {
          background: nth($background-colors, 5);
        }
      }
    }
  }
}
