.c-assignment {
  .c-assignment__actions {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    @include mq("max-width", "md") {
      display: none;
    }
    @include mq("min-width", "md") {
      height: 490px;
    }
  }
  .c-assignment__actions-btn {
    margin-bottom: 10px;
  }
  .c-assignment__target {
    height: 100%;
  }
  .c-assignment__source {
    @include mq("max-width", "md") {
      margin: 0 0 10px 0;
      padding: 0 0 10px 0;
      border-bottom: 2px solid #000;
    }
  }
}

.c-assignment-box {
  .c-assignment-box__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .c-assignment-box__body-item {
    margin: 2px 0;
    padding: 10px 0 0 0;
    border-top: 1px solid #d2d2d2;
    &:first-child {
      border: none;
    }
    &--table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mq("min-width", "md") {
        height: 490px;
      }
    }
  }
  &__table-wrapper {
    max-height: 413px;
    overflow-y: auto;
  }
  .c-assignment-box__footer {
    margin: 10px 0;
    padding: 16px 0 0 0;
    border-top: 1px solid #d2d2d2;
  }
}
