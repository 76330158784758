.simulated-balance {
  _:-ms-fullscreen,
  &__form {
    width: 100%;

    input.simulated-balance__date-picker {
      line-height: 1;
    }
  }
}
