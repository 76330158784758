@mixin input {
  padding: 8px 14px;
  // height: $input-height;
  height: auto;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #ced4da;
  user-select: none;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px $brand-color-1;
  }
}

.c-control {
  margin-bottom: 9px;

  &:last-of-type {
    margin-bottom: 0;
  }
  &--wrap {
    display: flex;
  }
  &--inline {
    display: flex;
  }
  &--radio {
    input {
      &[type="radio"] {
        visibility: hidden;
        position: absolute;
      }

      &[type="radio"]:checked + label:after,
      &[type="radio"]:not(:checked) + label:after {
        content: "";
        position: absolute;
        top: 8px;
        left: 2px;
        width: 10px;
        height: 10px;
        background: $brand-color-1;
        border-radius: 100%;
        transition: all 0.2s ease;
      }

      &[type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }

      &[type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  &--checkbox {
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
  }
  .c-control__group {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  .c-control__label {
    align-self: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
    line-height: 1.3;
    font-size: 14px;
    word-break: break-word;
    &--checkbox {
      display: flex;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
    }
    &--radio {
      position: relative;
      padding-top: 0;
      padding-left: 20px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        border: 1px solid #c1c1c1;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  .c-control__btn {
    height: 36px !important;
  }

  .c-control__required-sign {
    position: relative;
    &::before {
      content: "*";
      position: absolute;
      top: 0;
      left: 2px;
      color: $bright-red;
    }
  }

  .c-control__input {
    position: relative;
    &--error {
      border-color: red;
    }
    &--picker {
      padding-left: 48px;
      height: $input-height;
      cursor: pointer;
      outline: none;
      background: #fff;
    }
    input {
      @include input;
      &:disabled {
        background-color: #f3f3f3;
      }
    }
  }

  .c-control__input-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    padding: 0 5px;
    color: #a0a0a0;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .c-control__input-group {
    position: relative;
  }

  .c-control__label-icon {
    padding-right: 8px;
    font-size: 16px;
  }

  .c-control__suffix {
    padding-left: 8px;
  }

  .c-control__color-picker {
    position: absolute;
    z-index: 1;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 30px;
    border-radius: 4px;
  }

  .c-control__input-desc {
    display: inline-block;
    padding-top: 4px;
    width: 100%;
    font-size: 12px;
    text-align: right;
  }

  &.default-form {
    .DayPickerInput input {
      padding: 12px 18px;
      height: $input-height;
      line-height: 1;
      border: 1px solid #c7c7c7;
      user-select: none;
      cursor: pointer;
    }
  }

  input {
    @include input;
  }
}

.c-control--checkbox {
  margin: 0;

  input {
    visibility: hidden;
    position: absolute;

    &:checked + label:after,
    &:not(:checked) + label:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 2px;
      width: 8px;
      height: 8px;
      background: $brand-color-1;
      transition: all 0.2s ease;
    }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.c-control--checkbox-list {
    input {
      &:checked + label:after,
      &:not(:checked) + label:after {
        top: -5px;
      }
    }
  }

  i {
    margin-right: 5px;
  }

  .c-control__label {
    position: relative;
    margin: 0 18px;
    padding: 0 0 0 18px;
    font-weight: 400;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin-top: -1px;
      width: 12px;
      height: 12px;
      border: 1px solid #c1c1c1;
      background-color: #fff;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.c-control-group {
  &--wraped {
    display: flex;
  }
}
