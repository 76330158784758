@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/helpers/variables";
@import "../../../assets/styles/helpers/mixins";

$headerColor: #49484b;
$transparendBorder: 1px solid transparent;
$lightBorder: 1px solid #f0f0f0;

.bt {
  $self: &;
  overflow-x: auto;
  &.bt--no-overflow {
    overflow: unset;
  }
  &.bt--modal-margins {
    margin: 0 -1px 0 -1px;
  }
  &.bt--secondary {
    width: auto;
  }
  &__table {
    margin-bottom: 0.6rem;
    border-collapse: separate;
    border-spacing: 0 4px;
    border: none;
    table-layout: auto !important;

    &--decor-1 {
      tbody {
        tr {
          &:nth-child(2n) {
            td {
              background-color: #e6e6e6;
            }
          }
        }
      }
    }
  }
  &--small {
    margin-bottom: 0;
    tr td {
      font-size: 90%;
      padding: 0.4rem;
    }
  }
  &--selectable tr td {
    cursor: pointer;
  }
}

.bt-table {
  .align-to-right {
    text-align: right;
  }
  .bt-table__header {
    &.bt-table__header--fixed-width {
      width: 140px;
    }

    &.bt-table__header--single-icon {
      width: 60px;
    }

    &.bt-table__header--note-text-wide {
      width: 80%;
    }
    &.bt-table__header--text-center {
      text-align: center;
    }
    &.bt-table__header--text-right {
      text-align: right;
    }
  }

  .bt-table__cell {
    &.bt-table__cell--text-right {
      text-align: right;
    }

    &.bt-table__cell--text-center {
      text-align: center;
    }

    &.bt-table__cell--debtor {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $brand-color-1;
      max-width: 140px;
    }
  }

  .bt-table__cell-btn {
    display: inline-block;
    padding: 10px;
    width: auto;
  }

  .bt-table__action-buttons {
    i {
      font-size: 16px;
      margin-right: 8px;
      transition: $transition;
      cursor: pointer;
      float: left;
      &:hover {
        color: nth($text-colors, 1);
      }
    }
    label {
      margin-bottom: 0;
    }
  }

  .bt-table__row {
    td {
      transition: $transition;
    }
    &.bt-table__row--selected {
      color: nth($text-colors, 6);
      td {
        background: nth($background-colors, 3);
        a {
          color: #fff;
        }
        i.fa-square {
          &:before {
            content: "\f14a";
          }
        }
      }
    }
  }

  tr {
    &.bt-table__row--half-opacity {
      opacity: 0.5;
    }
  }

  &--visible-on-hover-button {
    tr {
      &:hover {
        .btn--visible-on-hover {
          opacity: 0.75;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &.bt-table--stripped {
    tr {
      td {
        border-top: $lightBorder;
        border-bottom: $lightBorder;
        &:first-child {
          border-left: $lightBorder;
        }
        &:last-child {
          border-right: $lightBorder;
        }
      }
    }
  }

  thead {
    padding: 8px;
    background: $grey5;
  }

  tr {
    border: none;
    td {
      padding: 0.45rem 0.6rem;
      border-top: $transparendBorder;
      border-bottom: $transparendBorder;
      border-left: 0;
      border-right: 0;
      background-color: white;
      transition: $transition;
      word-break: break-word;
      &:first-child {
        border-left: $transparendBorder;
        border-radius: 0;
      }

      &:last-child {
        border-right: $transparendBorder;
        border-radius: 0;
      }
    }

    &:hover {
      td {
        &:not(.react-bs-table-no-data) {
          border-top-color: $brand-color-1;
          border-bottom-color: $brand-color-1;
          &:first-child {
            border-color: $brand-color-1;
          }
          &:last-child {
            border-color: $brand-color-1;
          }
        }
      }
    }

    th {
      padding: 0.6rem 0.6rem;
      color: $headerColor;
      font-weight: bold;
      border: none !important;
      &.sortable {
        .react-bootstrap-table-sort-order {
          &.dropup {
            .caret {
              &:before {
                content: "\f0d8";
              }
            }
          }
          .caret {
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            &:before {
              content: "\f0d7";
            }
          }
        }
      }
    }
  }

  &--dark {
    tr {
      td {
        background-color: nth($background-colors, 2);
        border-color: nth($borders-colors, 1);
        &:first-child {
          border-left-color: nth($borders-colors, 1);
        }

        &:last-child {
          border-right-color: nth($borders-colors, 1);
        }
      }
    }
  }
}
