.c-form-group {
  margin: 0 0 ($skin-padding * 2) 0;
  &--bolded {
    .c-form-group__label {
      font-weight: 600;
    }
  }
  .c-form-group {
    margin: 0;
  }

  &.br {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 16px;
      padding-right: $skin-padding;
      width: 1px;
      border-right: 1px solid #d6d6d6;
    }
  }
  &.bl {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      padding-left: $skin-padding;
      width: 1px;
      border-left: 1px solid #d6d6d6;
    }
  }
  &--fieldset {
    legend {
      font-size: 14px;
      font-weight: 600;
    }
    .c-form-group__label {
      font-size: 11px;
      text-transform: uppercase;
    }
  }
  &--inline {
    display: flex;
    .c-form-group__label {
      margin: 8px $skin-padding 0 0;
      max-width: 40%;
    }
  }
  &__legend {
    margin: 0 0 4px 0;
    font-size: 14px;
  }
  &__label {
    margin: 0 $skin-padding 4px 0;
    font-size: 14px;
    word-break: break-word;
  }
  &__input {
    flex: 1;
  }
  &__input-label {
    text-align: right;
  }
}
