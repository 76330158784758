@import "../../assets/styles/base/colors";
@import "../../assets/styles/base/settings";

.c-chart {
  display: flex;
  justify-content: center;
  .c-chart__item {
    flex: 1;
    width: 100%;
    rect {
      fill: #f4f4f4;
      width: 100%;
      height: 7%;
      @each $color in $charts-colors {
        $i: index($charts-colors, $color);
        &.column-#{$i} {
          &.colored {
            fill: #{$color} !important;
          }
        }
      }
    }
  }
  .c-chart__header {
    margin-bottom: 12px;
    font-weight: 600;
    color: nth($text-colors, 4);
    text-align: center;
  }
  .c-chart__body {
    display: flex;
    justify-content: center;
    .chart {
      max-width: 60px;
    }
  }
  .c-chart__footer {
    margin-top: 12px;
    text-align: center;
  }
  .c-chart__cat-icons {
    i {
      display: block;
      font-size: 21px;
    }
    @each $color in $charts-colors {
      $i: index($charts-colors, $color);

      &.c-chart__cat-icons--#{$i} {
        i {
          color: #{$color} !important;
        }
      }
    }
  }
}
