@import "../../../assets/styles/base/colors";
@import "../../../assets/styles/base/settings";
@import "../../../assets/styles/helpers/variables";
@import "../../../assets/styles/helpers/mixins";

$transition: 0.3s ease-in-out;

.main-header {
  position: relative;
  z-index: 1030;
  display: block;
  @include mq("min-width", "sm") {
    max-height: 2 * $navbar-height;
  }

  .logo-container {
    display: flex;
    float: left;
    background: $brand-color-1;
    width: $leftMenuWidth;
    text-align: center;
    transition: width $transition;
    height: $navbar-height;
    line-height: $navbar-height;
    overflow: hidden;
    padding: 5px 15px;
    .logo {
      flex: 1;
      background: url("../../../assets/images/product-logo-white-symbol.svg") no-repeat center center;
      background-size: contain;
      background-position: center;
      height: $navbar-height - 10;
    }
  }
  .navbar {
    align-items: stretch;
    flex-wrap: nowrap;
    margin-bottom: 0;
    margin-left: $leftMenuWidth;
    padding: 0;
    min-height: $navbar-height;
    border: none;
    border-radius: 0;
    background-color: $brand-color-1;
    transition: margin-left $transition;
    @include mq("max-width", "sm") {
      // flex-direction: column-reverse;
    }
    .navbar-custom-menu {
      margin-left: auto;
      @media (min-width: $medium-screen) {
        height: 60px;
      }
    }
    .user-menu {
      &.dark {
        background-color: $grey2;
        .dropdown-item,
        .dropdown-item a {
          color: nth($text-colors, 6);
          &:hover,
          &:focus {
            background-color: $grey1;
          }
          &:active {
            background-color: $grey1;
            color: $brand-color-1;
          }
        }

        .custom-dropdown-item {
          @extend .dropdown-item;
          padding: 8px 12px;
          color: nth($text-colors, 6);
          &:hover,
          &:focus {
            background-color: $grey1;
          }
          &:active {
            background-color: $grey1;
            color: $brand-color-1;
          }
          .language-switch {
            display: inline-block;
            button {
              padding: 0;
              color: nth($text-colors, 6);
              line-height: 1;
              background: none;
              border: none;
              outline: none;
            }
            .selected {
              color: $grey3;
            }
          }
        }
      }
    }

    @media (max-width: $medium-screen) {
      margin: 0;
    }
  }
  .sidebar-toggle {
    display: none;
    color: nth($text-colors, 6);
    font-size: 24px;
    border: none;
    background: none;
    &:focus {
      outline: 0;
    }
    @include mq("max-width", "md") {
      display: inline-block;
    }
  }

  @include mq("max-width", "md") {
    .logo-container,
    .navbar {
      width: 100%;
      float: none;
    }
  }
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0 12px;
  height: 100%;
  @include mq("max-width", "xs") {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    // padding-top: 20px;
    height: auto;
  }
  .navbar-nav__item {
    &--secondary {
      cursor: default;
      &:hover {
        background: transparent;
      }
    }
  }
  li {
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;
    transition: background 0.3s ease;
    @include mq("max-width", "lg") {
      &:nth-child(2) {
        strong {
          display: none;
        }
      }
    }
    &:hover,
    &:active {
      color: #fff;
      background: lighten($brand-color-1, 10%);
    }
    a {
      color: #fff;
    }
    i {
      + * {
        margin-left: 5px;
      }
    }
  }
  .nav-link {
    padding: 0 12px;
    cursor: pointer;
    @media (max-width: $medium-screen) {
      padding: 16px 12px;
    }
    transition: opacity 0.3s ease;
    i {
      margin-right: 5px;
      &.disabled {
        transition: opacity 0.3s ease;
        opacity: 0.3;
      }
    }
    a {
      text-decoration: none;
    }
    .chat-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: -10px 0 0 -10px;
      &--available {
        background-color: $brand-color-1;
      }
    }
  }
  .dropdown {
    height: 100%;
  }
}

.c-creditor-switcher {
  &__menu {
    padding: 16px 0;
    width: 300px;
    max-height: 300px;
    overflow: hidden;
    border-radius: 0;
    border: 1px solid #efefef;
  }
  &__menu-item {
    padding: 0 18px;
  }
  &__search {
    margin: 0 0 18px 0;
    input {
      padding: 0 8px;
      width: 100%;
      height: 32px;
      background: #ececec;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      outline: none;
    }
  }
  &__list {
    padding: 0 8px 0 0;
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      @media (max-width: $medium-screen) {
        width: 1px;
      }
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: nth($scrollbar-colors, 2);
      border: 0px none $white;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-track {
      background: nth($scrollbar-colors, 1);
      border: 0px none $white;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  &__list-item {
    padding: 8px 0;
    font-size: 14px;
    border: none;
    white-space: normal;
  }
  &:hover a {
    color: $brand-color-2;
  }
}
