@import "../../assets/styles/base/settings";

.welcome-image {
  width: 100%;
  height: 100%;
  &-container {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: $large-screen) {
  .welcome-image {
    width: initial;
  }
}
